.loginWrapper {
   width: 100%;
   height: calc(100vh - 75px - 45px);
   display: flex;
   justify-content: center;
   align-items: center;
}

.loginFormContainer {
   width: 440px;
}